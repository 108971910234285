<template>
  <div class="projectData">
    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
        </div>
        <el-input
          placeholder="请输入嘉宾名称"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="projectTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        :default-sort="{prop: 'date', order: 'descending'}"
        @sort-change="sortChange"
      >
        <template slot="empty">
          <img
            src="@/assets/img/table.png"
            alt="没有数据"
            srcset=""
          />
        </template>
        <el-table-column prop="guestImgLook" label="嘉宾照片" width="200" align="center">
          <template v-slot="{ row }">
            <div v-if="row.guestImgLook">
              <el-image :src="row.guestImgLook" class="headerImg" />
            </div>
            <div v-else>
              <div v-if="row.guestSex == '男'">
                <el-image
                  :src="require('@/assets/img/male.png')"
                  class="headerImg"
                />
              </div>
              <div v-else>
                <el-image
                  :src="require('@/assets/img/female.png')"
                  class="headerImg"
                />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="guestName" label="嘉宾名称" align="center" width="100">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor:pointer;">
              <span>{{row.guestName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="guestSex" label="嘉宾性别" align="center" width="100"/>
        <el-table-column prop="activityCount" label="活动个数" align="center"  sortable="custom" width="100">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor:pointer;color:#1E9FFF">
              <span>{{row.activityCount}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="guestInstitution" label="嘉宾所在机构" align="center" width="200"/>
        <el-table-column prop="guestIdentity" label="嘉宾职位" align="center" width="200"/>
        <el-table-column prop="guestIntro" label="嘉宾简介" align="center">
          <template slot-scope="scope">
            <div>
              <el-popover
              placement="right"
              width="400"
              trigger="hover"
              :content="scope.row.guestIntro"
            >
              <div slot="reference">
                <div v-if="(scope.row.guestIntro !== null) && (scope.row.guestIntro !== '')">
                  {{scope.row.guestIntro.substring(0,100)}}
                  <span v-if="scope.row.guestIntro.length>100">...</span>
                </div>
              </div>
            </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50,100,200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Popover from '@/components/Popover.vue'
import CascaderArea from '@/components/CascaderArea.vue'
// import DataTime from '@/components/DataTime.vue'
import ImportData from '@/components/ImportData.vue'
import DicTagButton from '@/components/DicTagButton.vue'
import DicRadioButton from '@/components/DicRadioButton.vue'
import ButtonTable from '@/components/ButtonTable.vue'
import DataTime from "../../Product/com/dataTime.vue";
import { pagination } from '@/mixins/pagination'
import { getGuestList } from '@/api/activity'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
export default {
  name: 'ProjectData',

  mixins: [pagination],

  components: {
    ImportData,
    DicTagButton,
    DicRadioButton,
    ButtonTable,
    DataTime,
    CascaderArea,
    Popover,
    DicCheckboxGroup,
  },

  data() {
    return {
      // 查询
      queryInfo: {
        columnName: "update_time",
        pageSize: 10,
        pageNum: 1,
        queryConditions: '', // 搜索
        order: 1, // 0正序 1倒叙
        size: '',
      },
      projectTable: [], // 表格数据
      tags: [
        {
          name: '主办方类型',
          tagName: 'organizerType',
        },
      ],
      delete: {
        id: [],
        multiId: [],
      },
      dialogType: '行业类别',
      dialogVisible: false,
      editTableObj: {
        projectIndustryType: [], // 行业
        industryChildrenId: [], // 领域类别二级标签
        projectFieldType: [], // 领域
        fieldChildrenId: [], // 领域类别二级标签
      },
      valueI:0
    }
  },
  created() {
    this.search()
  },

  methods: {
    fileClick() {
      this.search()
    },
    async search() {
      const res = await getGuestList(this.queryInfo)

      if (res.code === 200) {
        this.projectTable = res.data.list
        this.total = res.data.total
      }

      this.total = res.data.total
    },
    searchCountry(val) {
      this.queryInfo.organizerCountry = val
      this.search()
    },
    searchProvince(val) {
      this.queryInfo.organizerProvince = val
      this.search()
    },
    searchCity(val) {
      if (val != '北京' && val != '天津' && val != '上海' && val != '重庆') {
        this.queryInfo.organizerCity = val
        this.search()
      }
    },
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return []
      } else if (!val.length) {
        return val
      } else {
        return val.split(',')
      }
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1)

      switch (name) {
        case 'reportTagsName':
          this.queryInfo.reportTagsName = ''
          this.queryInfo.reportTags = []
          break
        case 'techIdName':
          this.queryInfo.techIdName = ''
          this.queryInfo.techId = []
          break
        default:
          this.queryInfo[name] = ''
          break
      }
      this.search()
    },
    editItem(row) {
      // this.$router.push({
      //   name: 'guestInfo',
      //   query: {
      //     projectDataType: 1,
      //     id: row.id,
      //     name: row.guestName
      //   },
      // })
      let text = this.$router.resolve({
        path: "/exercise/guest/guestInfo",
        query: {
          projectDataType: 1,
          id: row.id,
          name: row.guestName
        },
      });
      window.open(text.href, "_blank");
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "activityCount") {
        this.queryInfo.columnName = "activityCount";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
  },
}
</script>
<style lang="less" scoped>
.projectData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content{
  padding: 0;
}
</style>
